import ICON_NAMES from 'constants/iconNames';
import IconButton from 'kit/IconButton';
import styled from 'styled-components';

const ButtonMic = ({ record, startRecording, stopRecording, ...props }) => {
  return (
    <>
      <ButtonMicro
        as={IconButton}
        iconSize={20}
        iconName={ICON_NAMES.micro}
        onMouseDown={startRecording}
        onMouseUp={stopRecording}
        onTouchStart={startRecording}
        onTouchEnd={stopRecording}
        isRecord={record}
        type="button"
        {...props}
      />
    </>
  );
};

export default ButtonMic;

const ButtonMicro = styled(IconButton)(({ isRecord, theme }) => {
  return {
    zIndex: theme.zIndex.element,
    padding: '8px',
    backgroundColor: 'red',
    borderRadius: '50%',
    borderBottomRightRadius: 0,
    border: '1px solid transparent',
    transform: isRecord ? 'translate(-30%,-30%) scale(1.5)' : '',
    boxShadow: isRecord ? '0px 4px 9px 0px #00000080 inset' : ''
  };
});
