import styled from 'styled-components';

export const TextH1 = styled.h1(({ theme }) => ({
  fontSize: '1.1rem',
  lineHeight: '1.2rem',
  fontWeight: 800,
  fontFamily: theme.font.bold,
  color: theme.color.white
}));

export const TextH2 = styled.h2(({ theme, color }) => ({
  fontSize: '1.1rem',
  lineHeight: '1.2rem',
  fontWeight: 600,
  fontFamily: theme.font.semiBold,
  color: color ? color : theme.color.white
}));

export const TextRegular = styled.p(({ theme, color }) => ({
  fontSize: theme.px.x4 + 'rem',
  lineHeight: theme.px.x5 + 'em',
  fontWeight: 400,
  fontFamily: theme.font.regular,
  color: color ? color : theme.color.white
}));

export const TextTag = styled.p(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '0.8rem',
  fontWeight: 400,
  fontFamily: theme.font.medium,
  color: theme.color.white
}));
