import ICON_NAMES from 'constants/iconNames';
import IconButton from 'kit/IconButton';
import styled from 'styled-components';

const ChatForm = ({ isRecording, onSubmit, inputValue, setInputValue, disabledInput }) => {
  return isRecording ? (
    <Placeholder />
  ) : (
    <ChatBottomForm onSubmit={onSubmit}>
      <Input
        value={inputValue}
        disabled={disabledInput}
        onChange={setInputValue}
        placeholder="Please enter your question"
      />
      <IconButton type={'submit'} iconSize={28} iconName={ICON_NAMES.send} />
    </ChatBottomForm>
  );
};

export default ChatForm;

const Placeholder = styled.div(() => ({
  width: '100%'
}));

const ChatBottomForm = styled.form({
  width: '100%',
  display: 'flex',
  alignItems: 'center'
});

const Input = styled.input(({ theme }) => ({
  width: '100%',
  padding: `${theme.px.x4}em ${theme.px.x4}em`,
  border: '1px solid transparent',
  outline: '1px solid transparent',
  fontSize: theme.px.x4 + 'rem',
  lineHeight: theme.px.x5 + 'em',
  color: theme.color.textRegular,

  '&::placeholder': {
    fontSize: theme.px.x4 + 'rem',
    lineHeight: theme.px.x5 + 'em',

    color: theme.color.placeholder
  }
}));
