export const colors = {
  black: '#262626',
  white: '#fff',
  colorPrimary: '#1DA5FF',
  colorTextClient: '#F4FAFF',
  colorLightBlueAcc: '#DBEFFF',
  recordBackground: '#FF7070',
  backdropColor: '#0000005C',
  textRegular: '#44525B',
  placeholder: '#c5c5c5',
  chatBackground: '#F4FAFF',
  recordPopup: 'rgba(52, 151, 219, 1)'
};
