import ICON_NAMES from 'constants/iconNames';
import logo from 'images/icons/logo.svg';
import IconButton from 'kit/IconButton';
import { TextH2, TextTag } from 'kit/text';
import styled from 'styled-components';

const headerTitles = {
  title: 'EzChatAI',
  subtitle: "Hi, I'm Stacy",
  description:
    " My name is Stacy, and I'm here to assist you in any way I can. How may I be of service to you today?"
};

const ChatHeader = ({
  isFirstLoad,
  isAudioEnabled,
  togglePermission,
  onClickRequest,
  onClickBulletList,
  loading,
  projectParamsSettings
}) => {
  return (
    <>
      <ChatHeaderStyle>
        <HeaderItem>
          <img width={38} height={38} src={logo} alt="ezchatai logo" />
          <TitlesWrapper>
            <TextH2>{headerTitles.title}</TextH2>
            <TextTag>{headerTitles.subtitle}</TextTag>
          </TitlesWrapper>
        </HeaderItem>
        <HeaderItem>
          {projectParamsSettings?.supports_audio && (
            <IconButton
              onClick={togglePermission}
              iconName={isAudioEnabled ? ICON_NAMES.play : ICON_NAMES.playoff}
            />
          )}
          <IconButton onClick={loading ? null : onClickRequest} iconName={ICON_NAMES.request} />
          <IconButton onClick={onClickBulletList} iconName={ICON_NAMES.bulletList} />
        </HeaderItem>
      </ChatHeaderStyle>
      {isFirstLoad && (
        <SubHeader>
          <SubHeaderContainer>
            <h3>{headerTitles.subtitle}</h3>
            <p>{headerTitles.description}</p>
          </SubHeaderContainer>
        </SubHeader>
      )}
    </>
  );
};
export default ChatHeader;

const SubHeader = styled.div({
  backgroundImage: 'linear-gradient(269.85deg, rgba(29, 165, 255, 0.56) -63%, #1DA5FF 103.78%)'
});

const SubHeaderContainer = styled.div({
  padding: '16px 33px 16px',
  borderTop: '1px solid rgba(255, 255, 255, 0.246)',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 'calc(100% - 60px)',
  margin: '0 auto',
  h3: {
    fontWeight: '700',
    fontSize: '17px',
    lineHeight: '20px',
    marginBottom: '14px'
  },
  p: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'center'
  }
});

const ChatHeaderStyle = styled.div({
  width: '100%',
  padding: '36px 28px 18px',
  backgroundImage: 'linear-gradient(269.85deg, rgba(29, 165, 255, 0.56) -63%, #1DA5FF 103.78%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const HeaderItem = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.px.x2 + 'em',
  alignItems: 'center',
  color: theme.color.white
}));

const TitlesWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.px.x1 + 'em'
}));
