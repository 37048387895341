import styled from "styled-components";

export const SwitchWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
export const Slider = styled.span`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  background-color: ${({ checked }) => (checked ? '#1DA5FF' : '#ccc')};
  transition: background-color 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    transition: transform 0.3s ease;
    transform: ${({ checked }) => (checked ? 'translateX(3px)' : 'translateX(24px)')};
    top: 3px; /* Adjust top position to center vertically */
  }
`;

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;