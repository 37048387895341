import React from 'react';

import {ReactComponent as DeleteIcon} from '../../../images/icons/devices/Delete.svg';
import {DeviceImage, OrderDescriptions, OrderName, OrderSide} from '../OrderItem/style';
import {
    CartItemContainer,
    CartItemContent,
    CartItemHeader,
    CartPrice, CartPriceSummary,
    CartPriceText, CartSummaryPrice, CartSummaryText,
    PriceDescriptionText
} from './style';
import styled from "styled-components";
import eld from "../../../images/icons/devices/eld.svg";
import sixPin from "../../../images/icons/devices/6_pin.png";
import sixteenPin from "../../../images/icons/devices/16_pin.png";
import ezsmartcam from "../../../images/icons/devices/ezsmartcam.svg";
import smartsafety from "../../../images/icons/devices/smartsafety.svg";
import stikers from "../../../images/icons/devices/stikers.png";
import ninePin from "../../../images/icons/devices/IMG_5592.jpg";
const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
`;
const images = {
    'eld.svg': eld,
    '6_pin.png': sixPin,
    '16_pin.png': sixteenPin,
    'ezsmartcam.svg': ezsmartcam,
    'smartsafety.svg': smartsafety,
    'stikers.png': stikers,
    'IMG_5592.jpg': ninePin
};
export const ServiceCartItem = ({ service,deleteService}) => {
    return (
        <CartItemContainer>
            <CartItemHeader>
                <OrderSide>
                    <div>
                        <DeviceImage src={images[service.service.imgUrl]} alt={service.service.imgUrl} />
                    </div>
                    <div>
                        <OrderName>{service.title}</OrderName>
                        <OrderDescriptions style={{fontSize: '11px', color: '#586771'}}>
                            FMCSA certified smart ELDwith easy installation
                        </OrderDescriptions>
                    </div>
                </OrderSide>
                <StyledDeleteIcon onClick={() => deleteService(service.id)}/>
            </CartItemHeader>
            <CartItemContent>
                    <CartPrice>
                        <PriceDescriptionText>First month fee</PriceDescriptionText>
                        <CartPriceText>${service.main_price.total}</CartPriceText>
                    </CartPrice>
                {service.fee_price &&
                    <CartPrice>
                        <PriceDescriptionText>Last month fee</PriceDescriptionText>
                        <CartPriceText>${service.fee_price.total}</CartPriceText>
                    </CartPrice>
                }
                {service.additional_price &&
                    <CartPrice>
                        <PriceDescriptionText>Device price</PriceDescriptionText>
                        <CartPriceText>${service.additional_price.total}</CartPriceText>
                    </CartPrice>
                }
                <CartPriceSummary>
                    <CartSummaryText>Summary</CartSummaryText>
                    <CartSummaryPrice>${service.price}</CartSummaryPrice>
                </CartPriceSummary>
            </CartItemContent>
        </CartItemContainer>
    );
};
