import styled from "styled-components";
export const TotalOrderContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
export const TotalOrderPrice = styled.div`
  font-size: 16px;
  color:#1DA5FF;
  font-weight:600;
  line-height: 24px;
`;
export const TotalOrderTitle = styled.div`
  font-size: 16px;
  color:#262626;
  font-weight:600;
  line-height: 24px;
`;
