export const pxs = {
  x0: 0,
  x1: 0.25,
  x2: 0.5,
  x3: 0.75,
  x4: 1,
  x5: 1.25,
  x6: 1.5,
  x7: 1.75,
  x8: 2,
  x9: 2.25,
  x10: 2.5,
  x11: 2.75,
  x12: 3,
  x13: 3.25
};
