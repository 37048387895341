import styled from "styled-components";

export const CartItemContainer = styled.div(({ theme }) => ({
    display: 'flex',
    margin: `${theme.px.x2}em 0px`,
    boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.12)',
    justifyContent:"space-between",
    borderRadius:"4px 4px 0 0",
    alignItems:"center",
    width: "calc(100% / 3 - 8px)",
    minWidth:"321px",
    flexDirection:"column",
    border:'0px 0px 1px 0px solid #E8F6FF',
    '@media (max-width: 768px)': {
        width: '100%', // Change the width to 100%
        minWidth: 'auto' // Remove the minimum width constraint
    },
}));
export const CartItemHeader = styled.div(({ theme }) => ({
    width:"100%",
    padding: '14px',
    display: 'flex',
    gap:'15px',
    borderBottom:'1px solid #E8F6FF',
    justifyContent:"space-between",
    alignItems:"center"
}));
export const CartItemContent = styled.div(({ theme }) => ({
    width:"100%",
    display: 'flex',
    flexDirection:"column",
    padding: "14px",
    gap:"15px",
    borderRadius:"4px, 4px, 0px, 0px",
}));
export const CartPrice = styled.div({
    display:"flex",
    justifyContent:"space-between",
    width:"100%",
    padding: '12px 10px',
    backgroundColor:"#E8F6FF",
});
export const CartPriceSummary = styled.div(({ theme }) => ({
    display:"flex",
    justifyContent:"space-between",
    width:"100%",
    padding: '12px 10px',
    color:"white",
    backgroundColor:theme.color.colorPrimary,
    borderRadius:"4px 4px 0 0",

}));
export const PriceDescriptionText = styled.span(({ theme }) => ({
    fontSize: theme.px.x3 + 'rem',
    color:'black'
}));
export const CartSummaryText = styled.span(({ theme }) => ({
    fontSize: theme.px.x3 + 'rem',
    color:'white'
}));

export const CartPriceText = styled.span(({ theme }) => ({
    fontSize: "14px",
    color:theme.color.colorPrimary,
    fontWeight:500
}));
export const CartSummaryPrice = styled.span(({ theme }) => ({
    fontSize: "14px",
    color:"white",
    fontWeight:500
}));
