import { socket } from '.';
import { USER_JOINED } from './events';

export const onWSConnect = () => {
  console.log('WS connected');
  socket.emit(
    USER_JOINED,
    // { jwt_token: Cookies.get('jwt_token') }
    {
      jwt_token: localStorage.getItem('jwt_token_user')
    }
  );
};

export const onMobileAction = (data) => {
  try {
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.chatHandler
    ) {
      window.webkit.messageHandlers.chatHandler.postMessage(data);
    } else if (window.android) {
      const jsonString = JSON.stringify(data);
      window.android.onMobileAction(jsonString);
    }
  } catch (error) {
    console.log(error);
  }
};

export const onNotifications = (data) => {
  try {
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.notificationsHandler
    ) {
      window.webkit.messageHandlers.notificationsHandler.postMessage(data);
    } else if (window.android) {
      const jsonString = JSON.stringify(data);
      window.android.onNotification(jsonString);
    }
  } catch (error) {
    console.log(error);
  }
};
