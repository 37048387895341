import React, { useState } from 'react';
import { roles } from '../../../constants/roles';
import { urlRegex } from '../../../constants/urlRegex';
import { OrderList } from '../../OrderList';
import { ActionButton } from '../ActionButton';
import { ClientMessage } from '../ClientMessage';
import { LiveAgentMessage } from '../LiveAgentMessage';
import {MessageInteraction} from '../MessageInteraction';
import { QuestionTag } from '../QuestionTag';
import {RelatedLinks} from "../RelatedLinks";

export const Message = ({
  message,
  handleAudioPlaying,
  isAudioEnabled,
  handleOnClickActionButton,
  handleOnClickTag,
  isLastMessage,
  setCurrentActionMode,
}) => {
  const [close, setClosed] = useState(false);
  if (roles.includes(message.role)) {
    return (
      <div
        style={{ overflow: 'hidden', position: 'relative' }}
        key={message.message + message.role}>
        <LiveAgentMessage
          message={
            message.message.match(urlRegex) ? (
              <div dangerouslySetInnerHTML={{ __html: message.message }}></div>
            ) : (
              message.message
            )
          }
          messageId={message.message_id}
          currentDate={message.created_at}
          isAudioEnabled={isAudioEnabled}
          handleAudioPlaying={handleAudioPlaying}
          isLastMessage={isLastMessage}
          setCurrentActionMode={setCurrentActionMode}
        />
          {message?.related_links && message?.related_links.length > 0 &&
              <RelatedLinks links={message.related_links} />
          }
        {isLastMessage && (
          <MessageInteraction
            setCurrentActionMode={setCurrentActionMode}
            messageText={message.message}
            message_id={message.message_id}
            isLiked={message.like}
          />
        )}
        {message.actions !== null && message?.actions?.length > 0 && (
          <ActionButton
            actions={message.actions}
            handleOnClickActionButton={handleOnClickActionButton}
          />
        )}
        {message.question_tags !== null && message?.question_tags?.length > 0 && (
          <QuestionTag tags={message.question_tags} handleOnClickTag={handleOnClickTag} />
        )}
        {message.open_purchase_window === true && !close && <OrderList setClosed={setClosed} />}
      </div>
    );
  }
  return <ClientMessage key={message.message + message.role} message={message} />;
};
