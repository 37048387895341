import styled from 'styled-components';

const Backdrop = ({ isOpen, onClose, color }) => {
  return (
    <>
      <BackdropStyled color={color} onClick={onClose} isOpen={isOpen} />
    </>
  );
};

export default Backdrop;

const BackdropStyled = styled.div(({ isOpen, color, theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: theme.zIndex.backdrop,
  backgroundColor: color ? color : theme.color.backdropColor,
  transition: 'opacity linear 250ms',
  opacity: isOpen ? 1 : 0,
  pointerEvents: isOpen ? 'all' : 'none',
  cursor: 'pointer'
}));
