import Backdrop from 'components/BackDropRoot';
import ReactDOM from 'react-dom';

const ModalPortal = ({show, onClick, children}) => {
    return (
        <div>
            {ReactDOM.createPortal(
                <Backdrop show={show} onClick={onClick}/>,
                document.getElementById('backdrop-root')
            )}
            {ReactDOM.createPortal(children, document.getElementById('overlay-root'))}
        </div>
    );
};

export default ModalPortal;
