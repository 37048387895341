import { createContext, useContext } from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from './theme';

const ThemeContext = createContext(theme);
export const useTheme = () => useContext(ThemeContext);

const ThemeContextManager = ({ children }) => {
  return (
    <ThemeContext.Provider value={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextManager;
