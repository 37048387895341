import styled from 'styled-components';

export const OrderContainer = styled.div(({ theme }) => ({
  position: 'relative',
  marginTop: `${theme.px.x4}em`,
  border: '1px solid #DBEFFF',
  borderRadius: '4px',
  minHeight: 200
}));
export const ButtonContainer = styled.div(({ theme }) => ({
  display: 'flex',
  marginTop: `${theme.px.x3}em`,
  gap: theme.px.x4 + 'rem',
  justifyContent: 'center'
}));
export const Title = styled.span(({ theme, isAddMargin }) => ({
  fontSize: '14px',
  display: 'in;ine-block',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 500,
  lineHeight: theme.px.x5 + 'em',
  marginLeft: isAddMargin ? theme.px.x4 + 'em' : 0
}));
export const SecondaryText = styled.span(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 500,
  lineHeight: theme.px.x5 + 'em',
  color: '#74818A'
}));
export const Button = styled.button(({ theme }) => ({
  padding: '11px 0',
  width: 163.5,
  borderRadius: 4,
  fontSize: theme.px.x4 + 'rem',
  color: 'white',
  backgroundColor: theme.color.colorPrimary,
  border: 0,
  cursor: 'pointer'
}));
export const CancelButton = styled.button(({ theme }) => ({
  padding: '11px 0',
  width: 163.5,
  borderRadius: 4,
  fontSize: theme.px.x4 + 'rem',
  color: 'black',
  backgroundColor: '#E8F6FF',
  border: '1px solid #DBEFFF',
  cursor: 'pointer'
}));

export const DetailsInput = styled.input(({ theme , error }) => ({
  backgroundColor: '#E8F6FF',
  height: '40px',
  width: '100%',
  padding: `0 ${theme.px.x2}em`,
  border: '1px solid transparent',
  fontSize: theme.px.x4 + 'rem',
  lineHeight: theme.px.x5 + 'em',
  color: theme.color.textRegular,
  borderRadius: '4px',
  '&:focus': {
    borderColor: 'transparent',
    outline: '1px solid #1da5ff'
  },
  '&::placeholder': {
    fontSize: theme.px.x4 + 'rem',
    lineHeight: theme.px.x5 + 'em',

    color: '#74818A'
  },
  ...(error && {
    borderColor: 'red',
    '&:focus': {
      outlineColor: 'red'
    }
  })
}));

export const Header = styled.div`
  margin: 1rem 0 1rem 1.5rem;
`;
export const Textarea = styled.textarea(({ theme }) => ({
  backgroundColor: '#e8f6ff',
  minWidth: '100%',
  maxWidth: '100%',
  borderRadius: '4px',
  padding: '8px',
  minHeight: '80px',
  color: 'rgb(68, 82, 91)',
  border: 0,
  maxHeight: '150px',
  fontFamily: 'Roboto',
  fontSize: '1rem',
  marginBottom:'8px',
  '&:focus': {
    borderColor: 'transparent',
    outline: '1px solid #1da5ff'
  },
  '&::placeholder': {
    fontSize: '1rem',
    lineHeight: theme.px.x5 + 'em',

    color: '#74818A'
  }
}));
export const DevicesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 300px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const DeliveryDetails = styled.div`
  margin: 1rem 0 1rem 1rem;
`;
export const DeliveryContainer = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: center;
  gap: 8px;`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;