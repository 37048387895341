import axios from 'axios';
import { apiUrl } from 'constants/api.constants';

export const API = axios.create({
  baseURL: apiUrl
});
export const configureInterceptorsForProject = (instance,id) => {
    instance.interceptors.request.use(
        (config) => {
            config.headers['project-id-jwt'] = id
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}
API.interceptors.request.use(
  async (config) => {
    // const token = Cookies.get('jwt_token');

      const token = localStorage.getItem('jwt_token_user');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['Accept'] = 'application/json';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const requests = {
  get: ({ url, params = {}, headers = {}, configs = {} }) =>
    API.get(url, {
      headers,
      params,
      ...configs
    }),
  post: ({ url, data, params = {}, headers = {}, configs = {} }) =>
    API.post(url, data, {
      headers,
      params,
      ...configs
    }),
  put: ({ url, data, params = {}, headers = {}, configs = {} }) =>
    API.put(url, data, {
      headers,
      params,
      ...configs
    }),
  delete: ({ url, params = {}, headers = {} }) =>
    API.delete(url, {
      headers,
      params
    }),
  patch: ({ url, data, params = {}, headers = {} }) =>
    API.patch(url, data, {
      headers,
      params
    })
};
