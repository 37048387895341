import React from 'react';
import { isBrowser, isMobile } from 'react-device-detect';

import { ReactComponent as StopRecording } from '../../images/icons/StopRecording.svg';
import { SoundWave } from '../SoundWave';
import { MessagesReadingContainer } from './style';

export const ChatReadingContainer = ({ isTextReading, setIsTextReading }) => {
  const stopMessageReading = () => {
    try {
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.stopAudioHandler
      ) {
        window.webkit.messageHandlers.stopAudioHandler.postMessage({
          isStopped: true
        });
      } else if (window.android) {
        const object = {
          isStopped: true
        };
        const jsonString = JSON.stringify(object);
        window.android.onAudioStop(jsonString);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsTextReading(false);
    }
  };
  return (
    <>
      {!isBrowser && isMobile && isTextReading && (
        <MessagesReadingContainer>
          <StopRecording onClick={stopMessageReading} style={{ cursor: 'pointer' }} />
          <span>Stop voice acting</span>
          <SoundWave />
        </MessagesReadingContainer>
      )}
    </>
  );
};
