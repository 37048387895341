import { colors } from './colors';
import { font } from './fonts';
import { pxs } from './pxs';
import { zIndex } from './zIndex';

export const theme = {
  px: pxs,
  font,
  color: colors,
  zIndex
};
