import { wsProtocol } from 'constants/api.constants';
import React, {useContext, useState} from 'react';
import io from 'socket.io-client';

const namespaceClient = '/client';
const SOCKET_URL = wsProtocol + document.domain + namespaceClient;
export const socket = io(SOCKET_URL);
export const SocketContext = React.createContext();
export const useDefaultParams = () => useContext(SocketContext);

const SocketProvider = ({ children }) => {
    const [isUserAuthorized, setIsUserAuthorized] = useState(false);
    return (
        <SocketContext.Provider value={{ isUserAuthorized, setIsUserAuthorized,ws:socket }}>
            {children}
        </SocketContext.Provider>
    );
};
export default SocketProvider;