import React, {useRef} from 'react';
import ModalPortal from "../../ModalPortal";
import {CSSTransition} from "react-transition-group";
import IconButton from "../../../kit/IconButton";
import ICON_NAMES from "../../../constants/iconNames";
import {MESSAGE_CLIENT} from "../../../services/socket/events";
import {useDefaultParams} from "../../../services/socket";
import './styles.scss'
import {convertNewMessageDate} from "../../../utils/functions/converDateToLocal";

const FAQModal = ({showFAQModal, onClickFAQModalHandler, setLoading, setMessages, chatID,faqList,id}) => {
    const {ws } = useDefaultParams();
    const nodeFAQRef = useRef(null);
    const onSubmitFAQModal = (message) => {
        try {
            setLoading(true);

            const data = {
                message,
                role: 'client',
                // jwt_token: Cookies.get('jwt_token'),
                jwt_token:localStorage.getItem('jwt_token_user'),
                chat_id: chatID ? chatID : null,
                project_id_jwt:id,
                created_at: convertNewMessageDate()

            };

            ws.emit(MESSAGE_CLIENT, data);

            setMessages((prevState) => [...prevState, data]);
            onClickFAQModalHandler();
        } catch (error) {
            console.log(error);
        }
    };

    return (
                <ModalPortal show={showFAQModal} onClick={onClickFAQModalHandler}>
                    <CSSTransition
                        mountOnEnter
                        unmountOnExit
                        in={showFAQModal}
                        timeout={{enter: 700, exit: 700}}
                        classNames="modal"
                        nodeRef={nodeFAQRef}
                    >
                        <div className="faq" ref={nodeFAQRef}>
                            <div className="faq_header">
                                <h2 className="faq_header_title">Frequently asked questions</h2>
                                <IconButton
                                    width={11}
                                    height={11}
                                    iconName={ICON_NAMES.cross}
                                    buttonStyle={{color: '#262626'}}
                                    onClick={onClickFAQModalHandler}
                                />
                            </div>
                            <div className="faq_content">
                                {faqList.length > 0 && faqList?.map((el, i) => {
                                    return (
                                        <div
                                            className="faq_content_item"
                                            key={el.id}
                                            onClick={() => onSubmitFAQModal(el.title)}>
                                            <span>{el.title}</span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="faq_footer"/>
                        </div>
                    </CSSTransition>
                </ModalPortal>
    );
};

export default FAQModal;
