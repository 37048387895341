import React from 'react';

import { MessageLiveAgent, Tag, Tags } from '../Message/styles';

export const QuestionTag = ({ handleOnClickTag, tags }) => {
  return (
    <MessageLiveAgent>
      <Tags>
        {tags?.map((tag) => (
          <Tag
            key={tag}
            onClick={() => {
              handleOnClickTag(tag);
            }}>
            {tag}
          </Tag>
        ))}
      </Tags>
    </MessageLiveAgent>
  );
};

