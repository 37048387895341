import React from 'react';

import { MessageLiveAgent } from '../Message/styles';
import { Tag, Tags } from './style';

export const ActionButton = ({ actions, handleOnClickActionButton }) => {
  return (
    <MessageLiveAgent>
      <Tags>
        {actions.map((action, index) => (
          <Tag
            key={index}
            onClick={() => {
              const clickedButton = { ...action, selected: true };
              handleOnClickActionButton(clickedButton);
            }}>
            {action?.title}
          </Tag>
        ))}
      </Tags>
    </MessageLiveAgent>
  );
};
