import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { ReactComponent as DislikeIcon } from '../../../images/icons/Dislike.svg';
import { ReactComponent as DislikeIconContained } from '../../../images/icons/DislikeContained.svg';
import { ReactComponent as LikeIcon } from '../../../images/icons/Like.svg';
import { ReactComponent as LikeIconContained } from '../../../images/icons/LikeContained.svg';
import { ReactComponent as CheckAll } from '../../../images/icons/Сheck-all.svg';
import { postLikeMessage } from '../../../services/chat';
import { Alert } from '../../Alert';
import { Container, Item, Text, Title } from '../ShowMessageInModal/style';
import {sendMessageToMobile} from "../../../utils/functions/sendMessageToMobile";
import clipboardCopy from 'clipboard-copy';

export const MoreDetailsInteraction = ({
  isLiked,
  message_id,
  messageText,
  setIsSharingModeActive,
  setMessages
}) => {

  const [isLikedMessage, setIsLikedMessage] = useState(isLiked);
  const [isMessageCopied, setIsMessageCopied] = useState(false);
  const dislikedMessage = isLikedMessage === false;
  const likedMessage = isLikedMessage === true;
  useEffect(() => {
    const copiedTimeout = setTimeout(() => {
      setIsMessageCopied(false);
    }, 3000);

    return () => {
      clearTimeout(copiedTimeout);
    };
  }, [isMessageCopied]);
  const setLikedMessage = async (likeStatus) => {
    const like = likeStatus === isLikedMessage ? null : likeStatus;
    try {
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.message_id === message_id ? { ...message, like: likeStatus } : message
        )
      );
      setIsLikedMessage(like);
      const response = await postLikeMessage({ message_id, like });
    } catch (error) {
      console.error('Failed to post like message:', error);
    }
  };
  const copyMessageTextToClipboard = () => {
    clipboardCopy(messageText)
        .then(() => setIsMessageCopied(true))
        .catch((error) => console.error('Failed to copy to clipboard:', error));
  };
  return (
    <Container>
      <Item onClick={() => setLikedMessage(false)}>
        {dislikedMessage ? (
          <DislikeIconContained width={18} height={17} />
        ) : (
          <DislikeIcon width={18} height={17} />
        )}
      </Item>
      <Item onClick={() => setLikedMessage(true)}>
        {likedMessage ? (
          <LikeIconContained width={18} height={17} />
        ) : (
          <LikeIcon width={18} height={17} />
        )}
      </Item>
      <Item onClick={copyMessageTextToClipboard}>
        <Text>{isMessageCopied ? 'Copied' : 'Copy'}</Text>
      </Item>
      {isMobile && (
        <>
          <Item>
            <Text onClick={() => sendMessageToMobile(messageText)}>Share</Text>
          </Item>
          <Item>
            <Text onClick={() => setIsSharingModeActive(true)}>Select More</Text>
          </Item>
        </>
      )}
      <Alert visible={isMessageCopied} type="success">
        <CheckAll />
        <Title>Message is copied</Title>
      </Alert>
    </Container>
  );
};
