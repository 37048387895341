import { requests } from './api';

export const getChats = () =>
  requests.get({ url: '/api/client/chats' }).then((res) => res?.data?.response?.chats);
export const getFAQ = () =>
    requests.get({url:'/api/faq'}).then((res) => res?.data?.response?.result);
export const getChat = (chatId) =>
  requests
    .get({ url: `/api/client/chats${chatId ? '/' + chatId : ''}`})
    .then((res) => res?.data?.response?.messages);
export const getNotifications = () =>
  requests.get({ url: '/api/notifications' }).then((res) => res?.data?.response?.messages);

export const postReadNotifications = () =>
  requests.post({ url: '/api/notifications_read' }).then((res) => res?.data?.response);

export const postAudio = (data) =>
  requests
    .post({
      url: '/api/audio_question',
      data,
      configs: {
        withCredentials: true
      }
    })
    .then((res) => res?.data);

export const getRequires = () =>
    requests.get({ url: '/api/requires_contacts'}).then((res) => res?.data?.response?.requires_contacts);

export const getProjectParams = () =>
    requests.get({ url: '/api/get_project_params'}).then((res) => res?.data?.response?.project_params);

export const postActionsButtons = (data) =>
    requests.post({ url: '/api/user_action',data}).then((res) => res?.data?.response);


export const postLikeMessage = (data) =>
    requests
        .post({ url: `/api/chat/message/rate`,data})
        .then((res) => res);