import React from 'react';

import { roles } from '../../../constants/roles';
import { urlRegex } from '../../../constants/urlRegex';
import { ClientMessage } from '../ClientMessage';
import { LiveAgentMessage } from '../LiveAgentMessage';
export const SelectingMessages = ({ message, isAudioEnabled = false, handleAudioPlaying }) => {
  if (message?.message?.answer) {
    return (
      <LiveAgentMessage message={message.message.answer} handleAudioPlaying={handleAudioPlaying} />
    );
  }
  if (roles.includes(message.role)) {
    return (
      <LiveAgentMessage
        message={
          message.message.match(urlRegex) ? (
            <div dangerouslySetInnerHTML={{ __html: message.message }}></div>
          ) : (
            message.message
          )
        }
        currentDate={message.created_at}
        isAudioEnabled={isAudioEnabled}
        handleAudioPlaying={handleAudioPlaying}
        isLastMessage={true}
      />
    );
  }
  return <ClientMessage key={message.message + message.role} message={message} />;
};
