import React, { useState } from 'react';

import { ReactComponent as ShareIcon } from '../../../images/icons/ShareWhite.svg';
import { sendMessageToMobile } from '../../../utils/functions/sendMessageToMobile';
import Checkbox from '../../Checkbox';
import { SelectingMessages } from '../SelectingMessages';
import { ShareButton, SharingContainer, Wrapping } from './style';
const SharingMessage = ({ messages, setIsSharingModeActive, setCurrentActionMode }) => {
  const [selectedMessages, setSelectedMessages] = useState([]);
  const handleShareMessage = () => {
    try {
      const messages = getSelectedMessageString(selectedMessages);
      sendMessageToMobile(messages);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSharingModeActive(false);
      setCurrentActionMode({ action: 'default', el: '' });
    }
  };
  const handleChange = (clickedMessage) => {
    setSelectedMessages((prev) =>
      prev.some((item) => item.message_id === clickedMessage.message_id)
        ? prev.filter((item) => item.message_id !== clickedMessage.message_id)
        : [...prev, clickedMessage]
    );
  };
  const getSelectedMessageString = (selectedMessages) => {
    return selectedMessages
      .sort((a, b) => a.message_id - b.message_id)
      .map((item) => item.message)
      .join('\n');
  };

  return (
    <>
      {messages.map((message, index) => (
        <SharingContainer onClick={() => handleChange({ ...message })} key={index}>
          <Wrapping>
            <Checkbox
              checked={selectedMessages.some((item) => item.message_id === message.message_id)}
            />
          </Wrapping>
          <SelectingMessages message={message} />
        </SharingContainer>
      ))}
      <ShareButton onClick={handleShareMessage}>
        <ShareIcon />
        <span>Share</span>
      </ShareButton>
    </>
  );
};
export default SharingMessage;
