import sprite from 'images/icons/sprite.svg';
import { createElement } from 'react';

const Icon = ({ name, width = '24px', height = '24px', ...props }) => {
  return (
    <svg height={height} width={width} {...props}>
      {createElement('use', {
        href: `${sprite}#${name}`,
        xlinkHref: `${sprite}#${name}`
      })}
    </svg>
  );
};

export default Icon;
