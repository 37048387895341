import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';

import { ReactComponent as Arrow } from '../../../images/icons/Arrow.svg';
import { ReactComponent as PreviosStep } from '../../../images/icons/devices/backImg.svg';
import { ErrorMessage } from '../../../pages/Chat/styles';
import { fetchUserData, makePurchase } from '../../../services/order';
import { phoneNumberRegex } from '../../../utils/regex/phoneNumberRegex';
import Checkbox from '../../Checkbox';
import { Loader } from '../../Loader/Loader';
import Switch from '../../Switch/Switch';
import { DevicesList } from '../Devices/style';
import {
  Button,
  ButtonContainer,
  CancelButton,
  DeliveryContainer,
  DetailsInput,
  DevicesContent,
  Header,
  OrderContainer,
  SecondaryText,
  Textarea,
  Title
} from '../style';
import { SummaryOrderDetails } from '../SummaryOrderDetails/SummaryOrderDetails';
import { OrderDetailsWrapper, SubmitOrderButton, VerticalItem } from './style';
import { usdotRegex } from '../../../utils/regex/usdotRegex';

const OrderDetails = ({
  setCurrentStep,
  deliveryType,
  isOvernight,
  handleOvernightTypeChange,
  handleDeliveryTypeChange,
  loading,
  total,
  setLoading,
  calculateDevices,
  productsCounters,
  serviceCounter,
  setClosed,
  zip,
  setZip
}) => {
  const [userData, setUserData] = useState({
    name: '',
    surname: '',
    phone: '',
    deliveryAddress: '',
    state: '',
    city: ''
  });
  const [referralCode, setReferralCode] = useState('');
  const [notes, setNotes] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if(!loading && zip.length === 5){
      calculateDevices()
    }

  }, [zip]);


  const getUserData = async () => {
    try {
      setLoading(true);
      const data = await fetchUserData();
      setUserData((prevUserData) => ({
        ...prevUserData,
        name: data.first_name || '',
        surname: data.last_name || '',
        phone: `+${data.phone || ''}`,
        deliveryAddress: data.delivery_address || '',
        state: data.state || '',
        city: data.city || '',
      }));
      setZip(data.zip || '');
    } catch (e) {
      setErrors((prevState) => ({ ...prevState, server: e?.response?.data?.error || e.message }));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  const handleReferralCode = () => {
    if (validateReferralCode(referralCode)) {
      calculateDevices(referralCode);
    }
  };
  const validateReferralCode = (referralCode) => {
    const trimmedReferralCode = referralCode.trim();
    const isValid = trimmedReferralCode !== '';

    setErrors((prevErrors) => ({
      ...prevErrors,
      referralCode: isValid ? '' : 'Incorrect referral code'
    }));

    return isValid;
  };
  const makeOrderPurchase = async () => {
    try {
      setLoading(true);
      const order = {
        name: userData.name,
        surname: userData.surname,
        phone: userData.phone,
        state: userData.state,
        city: userData.city,
        zip: zip.toString(),
        address: userData.deliveryAddress,
        addressesOrder: '',
        referralCode,
        notes,
        chat_id: +localStorage.getItem('chat_id'),
        products: Object.fromEntries(
          Object.entries(productsCounters)
            .filter(([k, v]) => v > 0)
            .map(([k, v]) => [k, String(v)])
        ),
        services: Object.fromEntries(
          Object.entries(serviceCounter)
            .filter(([k, v]) => v > 0)
            .map(([k, v]) => [k, String(v)])
        ),
        deliveryCalculateRate: deliveryType,
        overnight:isOvernight ? '1' : ''
      };
      const data = await makePurchase(order);
      setClosed(true);
    } catch (e) {
      setErrors((prevState) => ({ ...prevState, server: e?.response?.data?.error || e?.message }));
    } finally {
      setLoading(false);
    }
  };
  const validateForm = () => {
    const newErrors = {};

    if (!userData.name) {
      newErrors.name = 'First name is required';
    }

    if (!userData.surname) {
      newErrors.surname = 'Last name is required';
    }

    if (!userData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!phoneNumberRegex.test(userData.phone)) {
      newErrors.phone = 'Please enter a valid phone number';
    }

    if (!userData.deliveryAddress) {
      newErrors.deliveryAddress = 'Delivery address is required';
    }

    if (!userData.state) {
      newErrors.state = 'State is required';
    }

    if (!userData.city) {
      newErrors.city = 'City is required';
    }

    if (!zip) {
      newErrors.zip = 'Zip code is required';
    }
    if(!usdotRegex.test(zip)){
      newErrors.zip = 'The zip code consists only of numbers';
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid && !loading) {
      makeOrderPurchase();
    }
  };

  return (
    <>
      <OrderContainer>
        <Loader loading={loading} />
        <Header>
          <PreviosStep
            onClick={() => setCurrentStep('create-account')}
            style={{ cursor: 'pointer' }}
          />
          <Title isAddMargin>Details</Title>
        </Header>
        <DevicesList>
          <DevicesContent>
            <div>
              <Title>Delivery type</Title>
              <DeliveryContainer>
                <SecondaryText>Delivery</SecondaryText>
                <Switch onChange={handleDeliveryTypeChange} checked={deliveryType} />
                <SecondaryText>Customer pickup</SecondaryText>
              </DeliveryContainer>
              {deliveryType && (
                <Checkbox
                  disabled={loading}
                  label="Overnight shipping"
                  checked={isOvernight}
                  onChange={handleOvernightTypeChange}
                />
              )}
            </div>
            <div>
              <Title>Personal data</Title>
              <OrderDetailsWrapper>
                <div>
                  <DetailsInput
                    placeholder="First name"
                    value={userData.name}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        name: e.target.value
                      }));
                      setErrors({ ...errors, name: '' }); // Clear error when input changes
                    }}
                    error={errors.name}
                  />
                  {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="Last name"
                    value={userData.surname}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        surname: e.target.value
                      }));
                      setErrors({ ...errors, surname: '' }); // Clear error when input changes
                    }}
                    error={errors.surname}
                  />
                  {errors.surname && <ErrorMessage>{errors.surname}</ErrorMessage>}
                </div>
                <div>
                  <PhoneInput
                    inputComponent={DetailsInput}
                    international
                    value={userData.phone}
                    onChange={(phone) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        phone
                      }));
                      setErrors({ ...errors, phone: '' }); // Clear error when input changes
                    }}
                    placeholder="Phone number"
                  />
                  {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="Delivery address"
                    value={userData.deliveryAddress}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        deliveryAddress: e.target.value
                      }));
                      setErrors({ ...errors, deliveryAddress: '' }); // Clear error when input changes
                    }}
                    error={errors.deliveryAddress}
                  />
                  {errors.deliveryAddress && <ErrorMessage>{errors.deliveryAddress}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="State"
                    value={userData.state}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        state: e.target.value
                      }));
                      setErrors({ ...errors, state: '' }); // Clear error when input changes
                    }}
                    error={errors.state}
                  />
                  {errors.state && <ErrorMessage>{errors.state}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="City"
                    value={userData.city}
                    onChange={(e) => {
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        city: e.target.value
                      }));
                      setErrors({ ...errors, city: '' }); // Clear error when input changes
                    }}
                    error={errors.city}
                  />
                  {errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
                </div>
                <div>
                  <DetailsInput
                    placeholder="Zip code"
                    value={zip}
                    onChange={(e) => {
                      setZip(e.target.value)
                      setErrors({ ...errors, zip: '' }); // Clear error when input changes
                    }}
                    error={errors.zip}
                  />
                  {errors.zip && <ErrorMessage>{errors.zip}</ErrorMessage>}
                </div>
              </OrderDetailsWrapper>
            </div>
            <div>
              <Title>Others</Title>
              <OrderDetailsWrapper>
                <VerticalItem>
                  <DetailsInput
                    value={referralCode}
                    onChange={(e) => {
                      setErrors({ ...errors, referralCode: '' });
                      setReferralCode(e.target.value);
                    }}
                    placeholder="Referral Code"
                    error={errors.referralCode}
                  />
                  <SubmitOrderButton onClick={handleReferralCode}>Apply</SubmitOrderButton>
                </VerticalItem>
                <Textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Other notes"
                />
              </OrderDetailsWrapper>
            </div>
          </DevicesContent>
        </DevicesList>
        {errors.server && <ErrorMessage>{errors.server}</ErrorMessage>}
      </OrderContainer>
      <SummaryOrderDetails loading={loading} totalPrice={total} />
      <ButtonContainer>
        <Button onClick={handleSubmit}>Next <Arrow style={{ marginLeft: '5px' }} /></Button>
        <CancelButton onClick={() => setClosed(true)}>Cancel</CancelButton>
      </ButtonContainer>
    </>
  );
};

export default OrderDetails;
