import 'index.scss';

import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import SocketProvider from 'services/socket';
import ThemeContextManager from 'theme/ThemeContextManager';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <SocketProvider>
            <ThemeContextManager>
                <App/>
            </ThemeContextManager>
        </SocketProvider>
    </BrowserRouter>
);
