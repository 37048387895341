export function convertUTCDateToLocalDate(date) {
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000);
  return localDate;
}
export function convertDateToLocal(dateString) {
  const utcDate = new Date(dateString);
  const localDate = convertUTCDateToLocalDate(utcDate);
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };
  return localDate.toLocaleTimeString([], options);
}
export function convertNewMessageDate(){
  const data = new Date();

  // Step 2: Get the individual components of the date in UTC
  const year = data.getUTCFullYear();
  const month = String(data.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(data.getUTCDate()).padStart(2, '0');
  const hours = String(data.getUTCHours()).padStart(2, '0');
  const minutes = String(data.getUTCMinutes()).padStart(2, '0');
  const seconds = String(data.getUTCSeconds()).padStart(2, '0');

  // Step 3: Construct the formatted date string
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

}