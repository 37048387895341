import Icon from 'kit/Icon';
import styled from 'styled-components';

const IconButton = ({ iconName, onClick, iconSize, buttonStyle, ...props }) => {
  return (
    <Button style={buttonStyle} onClick={onClick} {...props}>
      <Icon width={iconSize} height={iconSize} name={iconName} />
    </Button>
  );
};

export default IconButton;

const Button = styled.button(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid transparent',
  borderRadius: '50%',
  backgroundColor: 'transparent',
  outline: 'none',
  cursor: 'pointer',
  transition: 'transform linear 200ms, box-shadow linear 200ms',
  color: theme.color.white,
  padding: theme.px.x1 + 'em',

  '&:hover, &:focus': {
    // outline: "none",
    // backgroundColor: "transparent",
    // border: "none",
    // boxShadow: "0px 4px 9px 0px #00000080",
  }
}));
