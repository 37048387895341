import { useState } from 'react';

export const useCounter = (initialValue = {}) => {
  const [state, setState] = useState(initialValue);
  const updateCounter = (id, value) => {
    setState((prevState) => ({
      ...prevState,
      [id]: value
    }));
  };
  const deleteCounter = (id) => {
    setState((prevState) => ({
      ...prevState,
      [id]: 0
    }));
  };
  return [state, updateCounter, deleteCounter];
};
