import ButtonMic from 'components/ButtonMic';
import ChatForm from 'components/ChatForm';
import RecordModule from 'components/RecordModule';
import logo from 'images/icons/logo.svg';
import { TextH2, TextRegular } from 'kit/text';
import SyncLoader from 'react-spinners/SyncLoader';
import styled, { keyframes } from 'styled-components';
import {ReactComponent as CleanButtonIcon} from "../images/icons/Clean.svg";

const ChatFooter = ({
  loading,
  record,
  onData,
  onStop,
  handleSubmit,
  startRecording,
  stopRecording,
  hideRecordModule,
  inputValue,
  setInputValue,
  projectParamsSettings,
  handleOpenNewChat
}) => {
  const override = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute'
  };
  return (
    <ChatFooterWrapper>
      {loading && (
        <ChatChip>
          <img src={logo} alt="logo" />
          <TextH2 color={'#1DA5FF80'}>EzChatAI </TextH2>
          <TextRegular color={'rgba(29, 165, 255, 0.5)'}>
            writes a message <Dots count={3} />
          </TextRegular>
        </ChatChip>
      )}
      <SyncLoader
        color="#1DA5FF"
        loading={loading}
        cssOverride={override}
        size={8}
        speedMultiplier={0.7}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {projectParamsSettings?.supports_audio &&
          <RecordModule hideModule={hideRecordModule} onData={onData} onStop={onStop} record={record} />
      }
      <CleanButtonIcon style={{cursor:"pointer"}} onClick={() => handleOpenNewChat({chat_id: null})} />
      <ChatForm
        onSubmit={handleSubmit}
        disabledInput={loading}
        isRecording={record}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
      {projectParamsSettings?.supports_audio &&
          <ButtonMic
              disabled={loading}
              startRecording={startRecording}
              stopRecording={stopRecording}
              record={record}
          />
      }
    </ChatFooterWrapper>
  );
};

export default ChatFooter;

export const Dots = ({ count }) => {
  const dots = [];
  for (let i = 0; i < count; i++) {
    dots.push(<Dot key={i} delay={i * 0.2} />);
  }
  return <>{dots}</>;
};

const ChatFooterWrapper = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: theme.px.x2 + 'em',
  paddingRight: theme.px.x2 + 'em',
  paddingLeft: theme.px.x2 + 'em',
  backgroundColor: theme.color.white
}));

const ChatChip = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.px.x2 + 'em',
  position: 'absolute',
  top: '-50%',
  left: 0,
  transform: 'translateX(10%)',

  '& img': {
    width: '24px',
    height: '24px'
  }
}));

export const dotAnimation = keyframes`
  0% {
    opacity: 0.2;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.5);
  }
  100% {
    opacity: 0.2;
    transform: scale(1);
  }
`;

export const Dot = styled.span`
  display: inline-block;
  margin: 0 5px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: rgba(29, 165, 255, 0.5);
  animation: ${dotAnimation} 1s infinite ease-in-out;
  animation-delay: ${(props) => props.delay || 0}s;
`;
