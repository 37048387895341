import React, {useEffect} from 'react';

import { ReactComponent as PreviosStep } from '../../../images/icons/devices/backImg.svg';
import Checkbox from '../../Checkbox';
import { Loader } from '../../Loader/Loader';
import Switch from '../../Switch/Switch';
import { ProductCartItem } from '../CartItem/ProductCartItem';
import { ServiceCartItem } from '../CartItem/ServiceCartItem';
import {
  CartItemContainer,
  CartItemContent,
  CartPriceSummary,
  CartSummaryPrice
} from '../CartItem/style';
import { DevicesList } from '../Devices/style';
import { OrderName } from '../OrderItem/style';
import {
  Button,
  ButtonContainer,
  CancelButton,
  DeliveryContainer,
  DeliveryDetails,
  Header,
  OrderContainer,
  SecondaryText,
  Title
} from '../style';
import { SummaryOrderDetails } from '../SummaryOrderDetails/SummaryOrderDetails';

export const Basket = ({
  deleteProduct,
  deleteService,
  setCurrentStep,
  devicesInBasket,
  isOvernight,
  deliveryType,
  handleOvernightTypeChange,
  handleDeliveryTypeChange,
  loading,
  total,
  setClosed,
  calculateDevices

                       }) => {
  useEffect(() => {
    calculateDevices();
  }, []);

  return (
    <>
      <OrderContainer>
        <Header>
          <PreviosStep
            onClick={() => setCurrentStep('devices-list')}
            style={{ cursor: 'pointer' }}
          />
          <Loader loading={loading} />
          <Title isAddMargin>Your selected</Title>
        </Header>
        <DeliveryDetails>
          <Title isAddMargin={false}>Delivery type</Title>
          <DeliveryContainer>
            <SecondaryText>Delivery</SecondaryText>
            <Switch onChange={handleDeliveryTypeChange} checked={deliveryType} />
            <SecondaryText>Customer pickup</SecondaryText>
          </DeliveryContainer>
          {deliveryType && (
            <Checkbox
              disabled={loading}
              label="Overnight shipping"
              checked={isOvernight}
              onChange={handleOvernightTypeChange}
            />
          )}
        </DeliveryDetails>
        <DevicesList>
          <>
            {devicesInBasket?.product_calculate?.products?.map((product) => (
              <ProductCartItem
                id={product.id}
                key={product.id}
                removeProductFromBasket={deleteProduct}
                product={product}
              />
            ))}
            {devicesInBasket?.product_calculate?.services?.map((service) => (
              <ServiceCartItem
                id={service.id}
                key={service.id}
                deleteService={deleteService}
                service={service}
              />
            ))}
            {devicesInBasket?.product_calculate?.delivery_price?.total > 0 && (
              <CartItemContainer>
                <CartItemContent>
                  <OrderName>Delivery price</OrderName>
                  <CartPriceSummary>
                    <CartSummaryPrice>
                      ${devicesInBasket?.product_calculate?.delivery_price?.total}
                    </CartSummaryPrice>
                  </CartPriceSummary>
                </CartItemContent>
              </CartItemContainer>
            )}
          </>
        </DevicesList>
      </OrderContainer>
      <SummaryOrderDetails loading={loading} totalPrice={total} />
      <ButtonContainer>
        <Button
          onClick={() => {
            if (
              !devicesInBasket?.product_calculate?.products &&
              !devicesInBasket?.product_calculate?.services
            ) {
              return;
            }
            setCurrentStep('order-details');
          }}>
          Next
        </Button>
        <CancelButton onClick={() => setClosed(true)}>Cancel</CancelButton>
      </ButtonContainer>
    </>
  );
};
