import styled from 'styled-components';

export const Tags = styled.div(() => ({
  display: 'flex',
  columnGap: '8px',
  rowGap: '16px',
  flexWrap: 'wrap'
}));

export const Tag = styled.button(() => ({
  padding: '8px',
  background: '#FFFFFF',
  color: '#1DA5FF',
  border: 'none',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '6px',
  cursor: 'pointer',
  textAlign: 'left'
}));
