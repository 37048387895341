import React, { useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { urlRegex } from '../../../constants/urlRegex';
import { sendMessageToMobile } from '../../../utils/functions/sendMessageToMobile';
import ModalPortal from '../../ModalPortal';
import { LiveAgentMessage } from '../LiveAgentMessage';
import { TransparentContainer } from '../Message/styles';
import { MoreDetailsInteraction } from '../MoreDetailsInteraction';
import {Item, Text, VerticalContainer} from "./style";

export const ShowMessageInModal = ({
  messages,
  currentActionMode,
  isAudioEnabled,
  handleAudioPlaying,
  onCloseDetails,
  setIsSharingModeActive,
  setCurrentActionMode,
  setMessages
}) => {
  if (currentActionMode.action === 'default') {
    return null;
  }
  const nodeRef = useRef(null);
  const selectedMessage = useMemo(
    () => messages.filter((item) => item.message_id === currentActionMode.el),
    [messages, currentActionMode.el]
  );
  return (
    <ModalPortal show onClick={onCloseDetails}>
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={currentActionMode.action !== 'default'}
        timeout={{ enter: 700, exit: 700 }}
        nodeRef={nodeRef}>
        <TransparentContainer>
          {selectedMessage.map((message, index) => (
            <div key={index} style={{ overflow: 'hidden', position: 'relative' }}>
              <LiveAgentMessage
                message={
                  message.message.match(urlRegex) ? (
                    <div dangerouslySetInnerHTML={{ __html: message.message }} />
                  ) : (
                    message.message
                  )
                }
                currentDate={message.created_at}
                isAudioEnabled={isAudioEnabled}
                handleAudioPlaying={handleAudioPlaying}
                setCurrentActionMode={setCurrentActionMode}
                messageId={message.message_id}
              />
              {currentActionMode.action === 'more' ? (
                <MoreDetailsInteraction
                  messageText={message.message}
                  isLiked={message.like}
                  setCurrentActionMode={setCurrentActionMode}
                  message_id={message.message_id}
                  setIsSharingModeActive={setIsSharingModeActive}
                  setMessages={setMessages}
                />
              ) : (
                <VerticalContainer>
                  <Item onClick={() => sendMessageToMobile(message.message)}>
                    <Text>Share</Text>
                  </Item>
                  <Item onClick={() => setIsSharingModeActive(true)}>
                    <Text>Select More</Text>
                  </Item>
                </VerticalContainer>
              )}
            </div>
          ))}
        </TransparentContainer>
      </CSSTransition>
    </ModalPortal>
  );
};
