import './styles.scss';

import { useEffect, useState } from 'react';

const Backdrop = ({ show, onClick }) => {
  const [startShowing, setStartShowing] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [isRendered, setIsRendered] = useState(show);

  useEffect(() => {
    if (show) {
      setIsRendered(true);
      setStartShowing(true);
    } else {
      setAnimation(false);
      setStartShowing(false);
    }
  }, [show]);

  useEffect(() => {
    let timer;
    if (isRendered && startShowing) {
      timer = setTimeout(() => {
        setAnimation(true);
      }, 0);
    } else {
      timer = setTimeout(() => {
        setIsRendered(false);
      }, 300); // Match the duration of the CSS transition
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isRendered, animation, startShowing]);
  return isRendered ? (
    <div className={`commonBackdrop ${animation ? 'showBackdrop' : ''}`} onClick={onClick}></div>
  ) : null;
};

export default Backdrop;
