import styled from 'styled-components';

export const MessageActions = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.px.x2 + 'em'
}));
export const MessageReaction = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.px.x2 + 'em'
}));
export const MessageInteractionContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.px.x2 + 'em',
  flexWrap: 'wrap',
  margin: `${theme.px.x1}em 0`,
  width: 'calc(100% - 20px)',
  backgroundColor: 'rgb(244, 250, 255)'
}));
export const ActionText = styled.span(({ theme }) => ({
  color: theme.color.colorPrimary,
  fontSize: theme.px.x4 + 'rem',
  marginLeft: `${theme.px.x1}em`
}));

export const CopiedTitle = styled.div(({ theme }) => ({
  marginLeft: `${theme.px.x1}em`
}));
