import Logo from 'images/icons/logo.svg';
import MessageSVG from 'images/icons/message.svg';
import NotificationSVG from 'images/icons/notification.svg';
import React, { useEffect, useRef, useState } from 'react';
import { getNotifications, postReadNotifications } from 'services/chat';
import { useDefaultParams} from 'services/socket';

import { MessageLiveAgentTags, Tag, Tags } from '../../components/Messages/Message/styles';
import {MOBILE_ACTION, NOTIFICATIONS} from '../../services/socket/events';
import { sendActionButton } from '../../utils/utils';
import {
  LogoImg,
  Notification,
  NotificationButton,
  NotificationImage,
  NotificationMsg,
  NotificationsBody,
  NotificationsHeader,
  NotificationsWrapper,
  NotificationTitle
} from './styles';
import {onMobileAction} from "../../services/socket/handlers";

const NotificationsPage = () => {
  const { ws } = useDefaultParams();

  const bottomRef = useRef(null);
  // ...Array(18).fill({ message: 'New Notification' }) // add to savedNotifications[] to check up scroll
  const [savedNotifications, setSavedNotifications] = useState([]);
  const handleNotifications = async () => {
    const notifications = await getNotifications();
    if (notifications.length) {
      setSavedNotifications(notifications);
    }
  };

  const handleOnClickActionButton = (actionButton) => {
    sendActionButton({ action_buttons: [actionButton] });
  };
  const onNotification = (notifications) => {
    setSavedNotifications((prevState) => [
      ...prevState,
      {
        has_been_read: false,
        message: notifications.message,
        actions: notifications?.action_buttons
      }
    ]);
  };
  useEffect(() => {
    handleNotifications();
    ws.on(NOTIFICATIONS, onNotification);
    ws.on(MOBILE_ACTION, onMobileAction);
    window.updateNotifications = () => {
      handleNotifications();
    };
    return () => {
      ws.off(MOBILE_ACTION, onMobileAction);
      ws.off(NOTIFICATIONS, onNotification);
      window.updateNotifications = null;
    };
  }, []);

  const handleMarkAsRead = async () => {
    const isMarkedAsRead = await postReadNotifications();
    if (isMarkedAsRead === 'OK') {
      setSavedNotifications([]);
    }
    try {
      if (window.webkit) {
        await window.webkit?.messageHandlers?.viewCloseHandler?.postMessage({});
      }
    } catch (error) {
      console.error(error);
    }
    console.log(`isMarkedAsRead: ${isMarkedAsRead}`);
  };

  useEffect(() => {
    if (bottomRef?.current) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [savedNotifications]);

  return (
    <NotificationsBody>
      <NotificationsHeader>
        <NotificationImage content={savedNotifications?.length || 0}>
          <img width={15} height={19} src={NotificationSVG} alt="notification.svg" />
        </NotificationImage>
        <NotificationTitle>New message</NotificationTitle>
        <NotificationButton onClick={handleMarkAsRead}>
          <img width={18} height={16} src={MessageSVG} alt="message.svg" />
          <span>Mark as read</span>
        </NotificationButton>
      </NotificationsHeader>
      <NotificationsWrapper>
        {savedNotifications?.map((el, index) => (
          <div key={el.message + index}>
            <Notification>
              <LogoImg width={21} height={21} src={Logo} alt="ezchatai logo" />
              <NotificationMsg>
                <span>{el.message}</span>
              </NotificationMsg>
            </Notification>
            {el.actions !== null && el?.actions?.length > 0 && (
              <MessageLiveAgentTags key={index}>
                <Tags>
                  {el.actions?.map((action, index) => (
                    <Tag
                      key={index}
                      onClick={() => {
                        const clickedButton = { ...action, selected: true };
                        handleOnClickActionButton(clickedButton);
                      }}>
                      {action?.title}
                    </Tag>
                  ))}
                </Tags>
              </MessageLiveAgentTags>
            )}
          </div>
        ))}
        <div ref={bottomRef} />
      </NotificationsWrapper>
    </NotificationsBody>
  );
};

export default NotificationsPage;
