import 'App.sass';
import ChatContent from 'components/ChatContent';
import ChatFooter from 'components/ChatFooter';
import ChatHeader from 'components/ChatHeader';
import { useLocalStorage } from 'hooks/useLocalStorage';
import React, { useEffect, useRef, useState } from 'react';
import { getChat, getFAQ, postAudio } from 'services/chat';
import {
  MESSAGE_CLIENT,
  MESSAGE_LIVE_AGENT,
  MOBILE_ACTION,
  NOTIFICATIONS,
  UPDATE_LOCATIONS,
  UPDATE_TIMEZONE
} from 'services/socket/events';
import { onMobileAction, onNotifications } from 'services/socket/handlers';
import { ContactsForm } from '../../components/ContactsForm';
import ChatHistoryModal from '../../components/modals/ChatHistoryModal';
import FAQModal from '../../components/modals/FAQModal';
import { delay } from '../../constants/delay';
import { useDefaultParams } from '../../services/socket';
import { fetchLocationData, retrieveUserLocation } from '../../services/socket/location';
import { timeZoneData } from '../../services/socket/timezone';
import { convertNewMessageDate } from '../../utils/functions/converDateToLocal';
import { sendActionButton } from '../../utils/utils';
import { Chat, ErrorMessage } from './styles';
import { ChatReadingContainer } from '../../components/ChatReadingContainer';

const ClientPage = ({
                      id,
                      showEmailAndPhoneForm,
                      setShowEmailAndPhoneForm,
                      projectParamsSettings,
                      session
                    }) => {
  const { ws } = useDefaultParams();
  const savedChatId = useRef(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isOpenFaQ, setIsOpenFaQ] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState('');
  const [isAudioEnabled, setIsAudioEnabled] = useState(projectParamsSettings?.supports_audio);
  const [record, setRecord] = useState(false);
  const [isRecordingButtonDisabled, setIsRecordingButtonDisabled] = useState(false);
  const [chatID, setChatID] = useLocalStorage('chat_id', 'null');
  const isAudioEnabledRef = useRef(isAudioEnabled);
  const timeoutRef = useRef(null);
  const [showFAQModal, setFAQShowModal] = useState(false);
  const [showChatHistoryModal, setShowChatHistoryModal] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [isTextReading, setIsTextReading] = useState(false);
  useEffect(() => {
    isAudioEnabledRef.current = isAudioEnabled;
  }, [isAudioEnabled]);

  useEffect(() => {
    savedChatId.current = chatID; // update the value of the reference whenever count changes
  }, [chatID]);

  const fetchMessages = async (chatId = '') => {
    try {
      getChat(chatId)
          .then((response) => {
            if (response?.length) {
              setIsFirstLoad(false);
              setIsOpenFaQ(false);
              setMessages(response);
            }
          })
          .catch((error) => {
            console.error(error);
          });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (isFirstLoad && chatID) {
      fetchMessages(chatID);
    }
  }, [isFirstLoad]);

  useEffect(() => {
    window.handleMessageFromApp = (message) => {
      if (message === 'StartRecording') {
        setRecord(true);
      } else if (message === 'StopRecording') {
        setRecord(false);
      } else if (message === 'FetchMessages') {
        fetchMessages(chatID);
      }
    };

    window.chatStateChanged = (closed) => {
      const data = {
        // jwt_token: Cookies.get('jwt_token'),
        jwt_token: localStorage.getItem('jwt_token_user'),
        chat_closed: closed,
        project_id_jwt: id,
        session
      };
      ws.emit(NOTIFICATIONS, data);
    };
    window.readingComplete = () => {
      setIsTextReading(false);
    };
    window.updateLocation = (latitude, longitude) => {
      const data = {
        // jwt_token: Cookies.get('jwt_token'),
        jwt_token: localStorage.getItem('jwt_token_user'),
        latitude: latitude,
        longitude: longitude,
        project_id_jwt: id,
        session
      };
      ws.emit(UPDATE_LOCATIONS, data);
    };
    return () => {
      window.readingComplete = undefined;
      window.handleMessageFromApp = undefined;
      window.chatStateChanged = undefined;
      window.updateLocation = undefined;
    };
  }, [messages?.length]);

  const handleOpenNewChat = async (chat) => {
    try {
      const chatIdFromModal = chat?.chat_id;
      const savedChat = chatIdFromModal ? await fetchMessages(chatIdFromModal) : null;
      setMessages(savedChat || []);

      setChatID(chatIdFromModal);
      if (chatIdFromModal === null) {
        setIsFirstLoad(true);
        setIsOpenFaQ(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const onMessageLiveAgent = (data) => {
      try {
        setLoading(false);
        setMessages((prevState) => [...prevState, { ...data, role: 'ai' }]);
        const chatIdFromLiveAgent = data?.chat_id;
        setChatID(chatIdFromLiveAgent);
        setIsOpenFaQ(false);
        setIsFirstLoad(false);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.messageHandler
        ) {
          window.webkit.messageHandlers.messageHandler.postMessage({
            message: data['message'],
            audio: isAudioEnabledRef.current
          });
        } else if (window.android) {
          const object = {
            message: data['message'],
            audio: isAudioEnabledRef.current
          };
          const jsonString = JSON.stringify(object);
          window.android.onMessage(jsonString);
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (isAudioEnabledRef.current) {
          setIsTextReading(true);
        }
      }
    };
    getFAQ().then((data) => {
      setFaqList(data);
    });
    fetchLocationData(ws, id);

    ws.emit(UPDATE_TIMEZONE, timeZoneData(id));
    ws.on(MOBILE_ACTION, onMobileAction);
    ws.on(MESSAGE_LIVE_AGENT, onMessageLiveAgent);
    ws.on(NOTIFICATIONS, onNotifications);

    return () => {
      ws.off(UPDATE_LOCATIONS, retrieveUserLocation);
      ws.off(UPDATE_TIMEZONE, timeZoneData);
      ws.off(MOBILE_ACTION, onMobileAction);
      ws.off(MESSAGE_LIVE_AGENT, onMessageLiveAgent);
      ws.off(NOTIFICATIONS, onNotifications);
    };
  }, []);

  const startRecording = async () => {
    if (isRecordingButtonDisabled) {
      return;
    }
    setIsRecordingButtonDisabled(true);
    setRecord(true);

    try {
      if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.recordingHandler
      ) {
        await window.webkit.messageHandlers.recordingHandler.postMessage({
          recording: true
        });
      } else if (window.android) {
        const object = {
          recording: true
        };
        const jsonString = JSON.stringify(object);
        window.android.onRecording(jsonString);
      }
    } catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      setIsRecordingButtonDisabled(false);
    }, 1000);
  };

  const stopRecording = () => {
    setRecord(false);
    try {
      if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.messageHandler
      ) {
        window.webkit.messageHandlers.recordingHandler.postMessage({
          recording: false
        });
      } else if (window.android) {
        const object = {
          recording: false
        };
        const jsonString = JSON.stringify(object);
        window.android.onRecording(jsonString);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // eslint-disable-next-line prettier/prettier
  const onData = (recordedBlob) => {
  };

  const onStop = async (recordedBlob) => {
    setLoading(true);

    if (isOpenFaQ) {
      setIsOpenFaQ(false);
      setIsFirstLoad(false);
    }

    // Set minimum and maximum duration limits (in seconds)
    const minDuration = 0.5 * 24000; // 0.5 second
    const maxDuration = 30 * 24000; // 30 seconds

    // Check if the audio duration is within the limits
    if (recordedBlob.blob.size < minDuration || recordedBlob.blob.size > maxDuration) {
      setLoading(false);
      return;
    }
    try {
      const formData = new FormData();
      if (messages.length > 0 && messages[messages.length - 1]?.actions !== null) {
        formData.append('action_buttons', JSON.stringify(messages[messages.length - 1]?.actions));
      }
      formData.append('audio', recordedBlob.blob);
      if (savedChatId.current) {
        formData.append('chat_id', savedChatId.current);
      }
      const audio = await postAudio(formData);
      if (audio?.question && typeof audio?.question === 'string' && audio?.chat_id) {
        fetchMessages(audio?.chat_id);
        setChatID(audio?.chat_id);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleNewMessageChange = (event) => {
    if (isError) {
      setIsError('');
    }
    if (!isFirstLoad) {
      setIsOpenFaQ(false);
    }
    setNewMessage(event.target.value);
  };

  const handleAudioPlaying = (message) => {
    try {
      if (isAudioEnabledRef.current) {
        if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.messageHandler
        ) {
          window.webkit.messageHandlers.messageHandler.postMessage({
            message: message,
            audio: true
          });
        } else if (window.android) {
          const object = {
            message: message,
            audio: true
          };
          const jsonString = JSON.stringify(object);
          window.android.onMessage(jsonString);
        }
      }
    } catch (error) {
      console.log(error);
    }
    finally{
      if (isAudioEnabledRef.current) {
        setIsTextReading(true);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newMessage === '') {
      setIsError('Type your question');
      return;
    }
    if (isOpenFaQ) {
      setIsOpenFaQ(false);
      setIsFirstLoad(false);
    }
    if (newMessage?.length > 500) {
      setIsError('Question must be less than 500 characters');
      return;
    }

    timeoutRef.current = setTimeout(() => setLoading(false), delay);

    const data = {
      message: newMessage,
      role: 'client',
      // jwt_token: Cookies.get('jwt_token'),
      jwt_token: localStorage.getItem('jwt_token_user'),
      chat_id: chatID ? chatID : null,
      project_id_jwt: id,
      created_at: convertNewMessageDate(),
      session
    };
    if (messages[messages.length - 1]?.actions !== null) {
      data.actions_buttons = messages[messages.length - 1]?.actions;
    }
    ws.emit(MESSAGE_CLIENT, data);
    setMessages((prevState) => [...prevState, data]);
    setNewMessage('');
    setLoading(true);
  };

  const handleTogglePermission = () => {
    setIsAudioEnabled((prevState) => !prevState);
  };

  const handleOnClickActionButton = (actionButton) => {
    const data = {
      message: actionButton.title,
      role: 'client',
      // jwt_token: Cookies.get('jwt_token'),
      jwt_token: localStorage.getItem('jwt_token_user'),
      chat_id: chatID ? chatID : null,
      project_id_jwt: id,
      session
    };

    // ws.emit(MESSAGE_CLIENT, data);
    sendActionButton({ action_buttons: [actionButton] });
    setMessages((prevState) => [...prevState, data]);
    setLoading(true);
  };
  const handleOnClickTag = (tag) => {
    const data = {
      message: tag,
      role: 'client',
      // jwt_token: Cookies.get('jwt_token'),
      jwt_token: localStorage.getItem('jwt_token_user'),
      chat_id: chatID ? chatID : null,
      project_id_jwt: id,
      session
    };

    ws.emit(MESSAGE_CLIENT, data);
    setMessages((prevState) => [...prevState, data]);
    setLoading(true);
  };

  const handleSubmitFaQ = (message) => {
    if (loading) {
      return;
    }
    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
    try {
      const data = {
        message,
        role: 'client',
        // jwt_token: Cookies.get('jwt_token'),
        jwt_token: localStorage.getItem('jwt_token_user'),
        chat_id: null,
        project_id_jwt: id,
        created_at: convertNewMessageDate(),
        session
      };

      ws.emit(MESSAGE_CLIENT, data);

      setMessages((prevState) => [...prevState, data]);
      setLoading(true);
      setIsOpenFaQ(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
      <>
        <Chat>
          <ChatReadingContainer setIsTextReading={setIsTextReading} isTextReading={isTextReading}/>
          <ChatHeader
              loading={loading}
              isAudioEnabled={isAudioEnabled}
              onClickRequest={() => setFAQShowModal((prev) => !prev)}
              onClickBulletList={() => setShowChatHistoryModal((prev) => !prev)}
              togglePermission={handleTogglePermission}
              isFirstLoad={isFirstLoad}
              projectParamsSettings={projectParamsSettings}
          />
          {showEmailAndPhoneForm ? (
              <ContactsForm setShowEmailAndPhoneForm={setShowEmailAndPhoneForm} />
          ) : (
              <>
                <ChatContent
                    faqList={faqList}
                    isFirstLoad={isFirstLoad}
                    isOpenFaQ={isOpenFaQ}
                    isAudioEnabled={isAudioEnabled}
                    messages={messages}
                    handleAudioPlaying={handleAudioPlaying}
                    handleOnClickActionButton={handleOnClickActionButton}
                    handleOnClickTag={handleOnClickTag}
                    handleSubmitFaQ={handleSubmitFaQ}
                    setMessages={setMessages}
                    isTextReading={isTextReading}
                />
                {isError && <ErrorMessage>{isError}</ErrorMessage>}
                <ChatFooter
                    handleSubmit={handleSubmit}
                    inputValue={newMessage}
                    setInputValue={handleNewMessageChange}
                    loading={loading}
                    onData={onData}
                    onStop={onStop}
                    record={record}
                    startRecording={startRecording}
                    stopRecording={stopRecording}
                    hideRecordModule={() => setRecord(false)}
                    projectParamsSettings={projectParamsSettings}
                    handleOpenNewChat={handleOpenNewChat}
                />
              </>
          )}
        </Chat>
        <ChatHistoryModal
            onClickChatHistoryHandler={() => setShowChatHistoryModal((prev) => !prev)}
            showChatHistoryModal={showChatHistoryModal}
            handleOpenNewChat={handleOpenNewChat}
        />

        <FAQModal
            faqList={faqList}
            chatID={chatID}
            setLoading={setLoading}
            setMessages={setMessages}
            showFAQModal={showFAQModal}
            id={id}
            onClickFAQModalHandler={() => setFAQShowModal((prev) => !prev)}
        />
      </>
  );
};

export default ClientPage;
