import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  flex: '1 1 auto',
  paddingBottom: theme.px.x8 + 'em',
  position: 'relative',
  display: 'flex',
  width: '100%',
  minHeight: '168px'
}));

export const MessagesWrapper = styled.div(({ theme, isHide }) => ({
  position: 'relative',
  width: '100%',
  padding: theme.px.x2 + 'em',
  transform: isHide ? 'translate(0,100%) scale(0)' : 'translate(0,0) scale(1)',
  opacity: isHide ? 0 : 1,
  transition: 'transform linear 300ms , opacity linear 400ms',
  transitionDelay: '200ms',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}));

export const MessageItem = styled.div(({ theme }) => ({
  width: 'calc(100% - 20px)',
  //   minHeight: "60px",
  padding: `${theme.px.x2}em ${theme.px.x4}em`,
  paddingRight: theme.px.x6 + 'em',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '21px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  whiteSpace: 'pre-wrap'
}));

export const MessageClient = styled(MessageItem)(({ theme }) => ({
  color: theme.color.colorTextClient,
  borderRadius: '12px 12px 0px 12px',
  backgroundColor: theme.color.colorPrimary,
  margin: '16px 0 0 auto',
  gap: '8px',
  '& span': {
    wordBreak: 'break-word',
    color: '#EAF6FF'
  }
}));

export const MessageLiveAgent = styled(MessageItem)(({ theme }) => ({
  width: 'calc(100% - 20px)',
  color: theme.color.colorPrimary,
  borderRadius: '12px 12px 12px 0',
  backgroundColor: theme.color.colorLightBlueAcc,
  margin: '16px auto 0 0',
  gap: theme.px.x2 + 'em',
  alignItems: 'flex-start',
  '& img': {
    width: '21px',
    height: '21px'
  },

  '& a': {
    color: theme.color.colorPrimary
  }
}));
export const DateMessage = styled.span(() => ({
  fontSize: '14px',
  whiteSpace: 'nowrap'
}));

export const Buttons = styled.div(() => ({
  display: 'flex',
  columnGap: '16px',
  flexWrap: 'wrap'
}));

export const Button = styled.button(({ isActive }) => ({
  padding: '8px 14px',
  border: '1px solid #1DA5FF',
  background: isActive ? '#1DA5FF' : '#FFFFFF',
  color: isActive ? '#FFFFFF' : '#1DA5FF',
  borderRadius: '6px',
  cursor: 'pointer',
  textAlign: 'center'
}));
export const MessageContent = styled.div(({ theme }) => ({
  flex: 1,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '21px',
  whiteSpace: 'pre-wrap'
}));
export const MessageLiveAgentTags = styled.div(({ theme }) => ({
  width: 'calc(100% - 20px)',
  color: theme.color.colorPrimary,
  borderRadius: '12px 12px 12px 0',
  backgroundColor: theme.color.colorLightBlueAcc,
  margin: ' 2px auto 0 0',
  padding: '16px 20px'
}));

export const Tags = styled.div(() => ({
  display: 'flex',
  columnGap: '8px',
  rowGap: '16px',
  flexWrap: 'wrap'
}));

export const Tag = styled.button(() => ({
  padding: '8px',
  background: '#FFFFFF',
  color: '#1DA5FF',
  border: 'none',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '6px',
  cursor: 'pointer',
  textAlign: 'left'
}));
export const TransparentContainer = styled.div(({ theme }) => ({
  position: 'fixed',
  zIndex: 9999,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  padding: '0px 15px',
  margin: '0 auto',
  background: 'transparent',
  overflow: 'hidden',
  borderRadius: '20px'
}));
