import styled from 'styled-components';

export const ShareButton = styled.button(({ theme }) => ({
  margin:'16px auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.color.white,
  fontSize: theme.px.x4 + 'rem',
  backgroundColor: 'rgba(52, 151, 219, 1)',
  padding: '0 11.25px',
  borderRadius: '6px',
  border: 0,
  cursor: 'pointer',
  width: 343,
  height: 48,
  boxShadow: 'rgba(9, 39, 59, 0.15), rgba(7, 26, 40, 0.25) ',
  fontWeight: '500',
  '& span': {
    marginLeft: '7px'
  }
}));
export const SharingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  cursor: pointer;

`;
export const Wrapping = styled.div`
  padding: 0 18px;
`;