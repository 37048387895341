import React from 'react';

import { convertDateToLocal } from '../../../utils/functions/converDateToLocal';
import { MessageChip } from '../LiveAgentMessage/style';
import { DateMessage, MessageClient, MessageContent } from '../Message/styles';

export const ClientMessage = ({ message }) => {
  return (
    <MessageClient>
      <MessageContent>
        {message.message}
        <MessageChip>
          <DateMessage>{convertDateToLocal(message.created_at)}</DateMessage>
        </MessageChip>
      </MessageContent>
    </MessageClient>
  );
};
