import { requests } from './api';

export const getOrdersDevices = () => requests.get({ url: '/api/order' }).then((res) => res?.data);
export const getCalculatedDevices = (data) =>
  requests.post({ url: '/api/order/calculate', data }).then((res) => res?.data);
export const makePurchase = (data) =>
  requests.post({ url: '/api/order', data }).then((res) => res?.data);

export const fetchUserData = () =>
    requests.get({ url: '/api/get_user_data' }).then((res) => res?.data);
export const createNewUser = (data) =>
    requests.post({ url: '/api/external_user',data }).then((res) => res.status);
