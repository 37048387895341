import React, { useState } from 'react';

import { Message } from '../Message/Message';
import SharingMessage from '../SharingMessages';
import { ShowMessageInModal} from '../ShowMessageInModal';

const MessagesList = ({
  messages,
  isAudioEnabled,
  handleAudioPlaying,
  handleOnClickActionButton,
  handleOnClickTag,
  setMessages
}) => {
  const [isSharingModeActive, setIsSharingModeActive] = useState(false);
  const [currentActionMode, setCurrentActionMode] = useState({ action: 'default', el: '' });
  const onCloseDetails = () => {
    setCurrentActionMode({ action: 'default', el: '' });
  };
  return (
    <>
      {isSharingModeActive ? (
        <SharingMessage
          messages={messages}
          setIsSharingModeActive={setIsSharingModeActive}
          setCurrentActionMode={setCurrentActionMode}
        />
      ) : (
        <>
          {messages.map((message, index) => (
            <Message
              setCurrentActionMode={setCurrentActionMode}
              isLastMessage={index === messages.length - 1}
              handleOnClickTag={handleOnClickTag}
              handleOnClickActionButton={handleOnClickActionButton}
              handleAudioPlaying={handleAudioPlaying}
              isAudioEnabled={isAudioEnabled}
              message={message}
              key={index}
            />
          ))}
          <ShowMessageInModal
            currentActionMode={currentActionMode}
            messages={messages}
            onCloseDetails={onCloseDetails}
            isAudioEnabled={isAudioEnabled}
            handleAudioPlaying={handleAudioPlaying}
            setIsSharingModeActive={setIsSharingModeActive}
            setCurrentActionMode={setCurrentActionMode}
            setMessages={setMessages}
          />
        </>
      )}
    </>
  );
};

export default MessagesList;
