import React, { useState } from 'react';
import { OrderItem } from '../OrderItem';
import { Button, ButtonContainer, CancelButton, Header, OrderContainer, Title } from '../style';
import { DevicesList, Tab, TabsContainer } from './style';
import { Loader } from '../../Loader/Loader';
const allValuesAreZero = (obj) => {
  return Object.values(obj).every((val) => val === 0);
};
function Devices({
  serviceCounter,
  productsCounters,
  updateDeviceCounter,
  items,
  setCurrentStep,
  loading,
  setClosed
}) {
  const [activeTab, setActiveTab] = useState('All');

  const tabs = [
    { name: 'All' },
    { name: 'Products' },
    { name: 'Services' },
    { name: 'Camera' },
    { name: 'ELD' }
  ];
  const getActiveFilterDev = (activeFilter) => {
    if (items?.products && items?.services) {
      switch (activeFilter) {
        case 'All':
          return [...items.services, ...items.products];
        case 'Products':
          return items.products;
        case 'Services':
          return items.services.filter((service) => service.category.id === 3);
        case 'Camera':
          return items.services.filter((service) => service.category.id === 2);
        case 'ELD':
          return items.services.filter((service) => service.category.id === 1);
        default:
          return items;
      }
    }
  };
  const filteredDevices = getActiveFilterDev(activeTab);
  return (
    <>
      <OrderContainer>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <>
            <Header>
              <Title isAddMargin={false}>Order list</Title>
            </Header>
            <TabsContainer>
              {tabs.map((tab) => (
                <Tab
                  key={tab.name}
                  className={activeTab === tab.name ? 'active' : ''}
                  onClick={() => setActiveTab(tab.name)}>
                  {tab.name}
                </Tab>
              ))}
            </TabsContainer>
            <DevicesList>
              {filteredDevices?.map((device) => (
                <OrderItem
                  device={device}
                  price={device.price}
                  description={device.description}
                  icon={device.imgUrl}
                  name={device.name || device.title}
                  key={device.id + device.price}
                  deviceCounter={
                    device.name ? productsCounters[device.id] : serviceCounter[device.id]
                  }
                  updateDeviceCounter={updateDeviceCounter}
                />
              ))}
            </DevicesList>
          </>
        )}
      </OrderContainer>
      <ButtonContainer>
        <Button
          onClick={() => {
            if (allValuesAreZero(productsCounters) && allValuesAreZero(serviceCounter)) {
              return;
            }
            setCurrentStep('create-account');
          }}>
          Go to cart
        </Button>
        <CancelButton onClick={() => setClosed(true)}>Cancel</CancelButton>
      </ButtonContainer>
    </>
  );
}

export default Devices;
