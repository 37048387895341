export const MESSAGE_CLIENT = 'message-client';
export const MESSAGE_LIVE_AGENT = 'message-live-agent';
export const MOBILE_ACTION = 'mobile-action';
export const NOTIFICATIONS = 'notifications';
export const UPDATE_LOCATIONS = 'update-locations';
export const UPDATE_TIMEZONE = 'update-timezone';

export const USER_JOINED = 'join';
export const USER_LEFT = 'left';

export const CONNECT = 'connect';
export const DISCONNECT = 'disconnect';
